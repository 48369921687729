<template>
  <div class="login flex f-center f-jcenter">
    <div class="login-box flex">
      <img class="bg01" src="~@/assets/img/login/bg01.png" />
      <div class="input-box flex f-center flex-direction f-around">
        <div class="title t-center">
          <div class="ma-b-10">欢迎使用苏尔寿访客系统</div>
          <div class="en">Welcome to the visitor system</div>
        </div>
        <div class="width60">
					<div class="flex f-center input">
					  <span class="iconfont icon-shouji"></span>
					  <van-field v-model="username" placeholder="请输入用户名称" />
					</div>
					<div class="flex f-center input">
					  <span class="iconfont icon-anquanrenzheng"></span>
					  <van-field v-model="password" type="password" placeholder="请输入密码" />
					</div>
				</div>
        
        <div class="btn" @click="submit">登 录</div>
       
      </div>
    </div>
  </div>
</template>

<script>
let _this;
import { mapActions } from 'vuex'
export default {
  data(){
    return {
      username: '',
      password: ''
    }
  },
  created (){
    _this = this;
  },
  methods:{
	  // 登录
    ...mapActions(['Login']),
    async submit(){
      if (_this.$validator.isEmpty(_this.username)) {
        _this.$toast('请输入用户名称');
        return
      }
      if (_this.$validator.isEmpty(_this.password)) {
        _this.$toast('请输入密码');
        return
      }
      _this.Login({
        username: _this.username,
        password: _this.password,
      })
      .then((res) => _this.$toast.success({
        message: '登录成功',
        duration: '1500',
        onClose() {
          _this.$router.replace('/')
        }
      }))
      .catch(err => console.log(err) )
    },
  }
}
</script>

<style lang="less" scoped>
  .login{
    background-image: url('~@/assets/img/login/bg.png');
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: fixed;
    .bg01{
      width: 300px;
      height: 300px;
    }
    .login-box{
      
      .input-box{
        width: 300px;
        height: 300px;
        background: #FFFFFF;
        
        .title{
          margin: 25px 0 0;
          font-size: 20px;
          font-weight: bold;
          color: #1D2029;
          .en{
            font-size: 14px;
            text-transform: uppercase;
          }
        }
        .input{
          margin: 20px 0;
          padding: 0 6px;
          border: 1px solid #CAD1D7;
          border-radius: 5px;
          .iconfont{
            color: #8994A2;
          }
          .van-cell{
            padding: 8px 5px;
          }
        }
        .btn{
          width: 240px;
          height: 35px;
          background: #003AA9;
          border-radius: 4px;
          margin: 0 auto 25px;
          text-align: center;
          line-height: 35px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .forget{
          margin: 15px 20px 0;
          a{
            color: #38A9CE;
          }
        }
      }
    }
  }
</style>

